import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import headerLogo from "../../assets/img/logo/logo.svg";
// import Image from 'next/image';
import { imageLoader } from '../../hooks/ImageLoader';
import Menus from './menus';
import OffcanvasArea from '../../components/common/offcanvas-area/OffcanvasArea';
import { useLocation } from 'react-router-dom';
import useStickyHeader from '../../hooks/sticky-header';
import Language from './Language';
import { useTranslation } from 'react-i18next';
const HeaderOne = () => {
    const isSticky = useStickyHeader();
    const [isOpenInfoSidebar, setIsOpenInfoSidebar] = useState(false);
    const handleInfoToggle = () => {
        setIsOpenInfoSidebar(!isOpenInfoSidebar);
    };
    const location = useLocation()

    const pathName = location.pathname;
    const { t } = useTranslation()
    const { home } = t("screen")


    return (
        <>
            <header className='ms-header-fixed'>
                <div id='header-sticky' className={`header__area header-1 ms-header-fixed ms-bg-body ${isSticky ? "sticky" : ""}`}>
                    <div className="container " >
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="mega__menu-wrapper p-relative">
                                    <div className={pathName === '/' ? "header__main d-flex ms-border2 align-items-center justify-content-between" :
                                        "header__main d-flex align-items-center justify-content-between"} >
                                        {/* style={{ paddingLeft: "2%", paddingRight: "2%" }} */}
                                        <div className="header__logo">
                                            <Link to="/">
                                                {/* width={143} height={45} ,priority*/}
                                                <img style={{ width: "15em", }} src={headerLogo} alt="logo not found" />
                                            </Link>
                                        </div>
                                        {/* <div className="d-none d-xl-inline-block">
                                            <div className="browse-filter p-relative ms-browse-act-wrap">
                                                <div className="ms-browse-icon ms-cp">
                                                    <Link className="browse-filter__text" href="#">
                                                        <RxHamburgerMenu />

                                                        <span> Browse Category</span>
                                                    </Link>
                                                </div>
                                                <div className="ms-browse-act-item-wrap p-absolute">
                                                    {category?.category?.map((item) => (
                                                        <div className="ms-song-item" key={item._id}>
                                                            <div className="ms-song-img p-relative">
                                                                <Link href="#">
                                                                    <img priority style={{ width: "100%", height: "auto" }} src={item?.icon} width={100} height={100} alt="brand-song" />
                                                                </Link>
                                                            </div>
                                                            <div className="ms-song-content">
                                                                <h3 className="ms-song-title"><Link href="#">{item?.category}</Link></h3>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="header__right ">
                                            <div className="mean__menu-wrapper">
                                                <div className="main-menu main-menu-ff-space d-none d-lg-inline-block" >
                                                    <nav>
                                                        <ul  >
                                                            <Menus />

                                                        </ul>
                                                    </nav>
                                                    <div className="header__hamburger ml-50 d-none link">
                                                        <button type="button" className="hamburger-btn offcanvas-open-btn link">
                                                            <span>01</span>
                                                            <span>01</span>
                                                            <span>01</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hidden md:flex">
                                            <Language  />
                                            </div>
                                        

                                            <div className="header__hamburger" style={{ color: "red" }}>
                                                <div className="sidebar__toggle" style={{ color: "red" }}>
                                                    <button onClick={handleInfoToggle} className="bar-icon" style={{ color: "red", }}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <OffcanvasArea isOpenInfoSidebar={isOpenInfoSidebar} setIsOpenInfoSidebar={setIsOpenInfoSidebar} />
            <div onClick={() => setIsOpenInfoSidebar(false)} className={isOpenInfoSidebar ? "offcanvas__overlay overlay-open" : "offcanvas__overlay"}></div>
        </>
    );
};

export default HeaderOne;
