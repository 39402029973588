import React from 'react'
import bannerTitleBg2 from '../../assets/img/banner/title-bg.jpg';
// import girls from "../../assets/img/logo/girl.png";
import girls from "../../assets/img/images/Keep Alive Your Passions.png"
// import money from "../../assets/img/logo/money.png";
import money from "../../assets/img/images/Save Money, Save Time and Reduce Pollution.png"
// import mobile from "../../assets/img/images/Expand Your Network (1).png"
import mobile from "../../assets/img/images/Expand Your Network(2).png"
// import driver from "../../assets/img/logo/driver.png";
import driver from "../../assets/img/images/Reliable Driver(4).png"
import { useTranslation } from 'react-i18next';
import searchForEvent from "../../assets/img/images/Search for an Event(3).png"
import bookASeat from "../../assets/img/images/Book a Seat(2).png"
import expandNetwork from "../../assets/img/images/Expand your Network (H)(2).png"
import offerARide from "../../assets/img/images/Offer a Ride.png"

const WhyChooseUs = () => {
    const { t } = useTranslation();
    const { home } = t("screen");

    return (
        <section className="pb-50 pt-50" id='features'>
            <div>
                <div className="section__title-wrapper mb-65 text-center bd-title-anim mb-25">
                    <h2 className="section__subtitle" style={{ fontFamily: 'Oregano-Italic, sans-serif',textTransform:"none", fontSize:"2.5rem" }}>
                        {home?.howTtWorks}
                    </h2>
                </div>
                <div className="pt-3 pb-0">
                    <div className="ms-fun-brand-wrap2 pt-2">
                        <div className='flex justify-center items-center' style={{ flexDirection: "column" }}>
                            <div style={{ height: '200px', display: 'flex', alignItems: 'center' }}>
                                <img className='why_chose_img' src={searchForEvent} alt="" />
                            </div>
                            <div style={{ height: '100px', display: 'flex', flexDirection: "column", alignItems: 'center', textAlign: 'center' }}>
                                <p className=" mt-4" style={{ fontSize: "26px", fontWeight: "bold", textTransform: "none" }}>
                                    {home?.SearchForAnEvent}
                                </p>
                                <p className='text-base mt-4' style={{ textTransform: "none" }}>{home?.Seethemaporfilterbydate}</p>
                            </div>
                        </div>
                        <div className='flex justify-center items-center' style={{ flexDirection: "column" }}>
                            <div style={{ height: '200px', display: 'flex', alignItems: 'center' }}>
                                <img src={offerARide} alt="" className="why_chose_img" />
                            </div>
                            <div style={{ height: '100px', display: 'flex', flexDirection: "column", alignItems: 'center', textAlign: 'center' }}>
                                <p className=" mt-4" style={{ fontSize: "26px", fontWeight: "bold", textTransform: "none" }}>
                                    {home?.Offeraride}
                                </p>
                                <p className='text-base mt-4' style={{ textTransform: "none" }}>{home?.Planthetriptoleadthegrouptotheevent}</p>

                            </div>
                        </div>
                        <div className='flex justify-center items-center' style={{ flexDirection: "column" }}>
                            <div style={{ height: '200px', display: 'flex', alignItems: 'center' }}>
                                <img src={bookASeat} alt="" className="why_chose_img" />
                            </div>
                            <div style={{ height: '100px', display: 'flex', flexDirection: "column", alignItems: 'center', textAlign: 'center' }}>
                                <p className=" mt-4" style={{ fontSize: "26px", fontWeight: "bold", textTransform: "none" }}>
                                    {home?.Bookaseat}
                                </p>
                                <p className='text-base mt-4' style={{ textTransform: "none" }}>{home?.Gototheeventandcomebackhomewithyourfavoritedriver}</p>
                            </div>
                        </div>
                        <div className='flex justify-center items-center' style={{ flexDirection: "column" }}>
                            <div style={{ height: '200px', width: "12rem", display: 'flex', alignItems: 'center' }}>
                                <img src={expandNetwork} alt="" className="" />
                            </div>
                            <div style={{ height: '100px', display: 'flex',flexDirection: "column", alignItems: 'center', textAlign: 'center' }}>
                                <p className=" mt-4" style={{ fontSize: "26px", fontWeight: "bold", textTransform: "none" }}>
                                    {home?.Expandyournetwork}
                                </p>
                                <p className='text-base mt-4' style={{ textTransform: "none" }}>{home?.Gototheeventandcomebackhomewithyourfavoritedriver}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyChooseUs;
