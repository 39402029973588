import { Listbox, Transition } from '@headlessui/react'
import React, { useState, Fragment, useEffect } from 'react'
import Usa from "../../assets/usa.png";
import Uk from "../../assets/uk.png";
import It from "../../assets/it_flag.png";
import { useTranslation } from "react-i18next";
const language = [{ name: "En", image: Uk },
{ name: "It", image: It },]
const Language = () => {
    // const [selected ,setSelected]=useState(language[0])
    const { t, i18n } = useTranslation()
    const lngIndex = localStorage.getItem("languageIndex")
    const [selected, setSelected] = useState(lngIndex ? language[lngIndex] : language[0]);
    const handleLanguage = (item, i) => {
        setSelected(item)
        i18n.changeLanguage(item?.name)
        localStorage.setItem("selectedLanguage", item?.name)
        localStorage.setItem("languageIndex", i)
    }
    return (
        <div>


            <Listbox value={selected} onChange={setSelected}>

                <div className="relative z-[22]">
                    <Listbox.Button className="relative w-full flex items-center cursor-pointer space-x-[6px] rtl:space-x-reverse">
                        <span className="inline-block md:h-6 md:w-6 w-4 h-4 rounded-full">

                            <img
                                src={selected?.image ? selected?.image : language[0].image}
                                alt=""
                                className="h-full w-full object-cover rounded-full"
                            // style={{ width: "2.5em", height: "1.8em", borderRadius: "2em" }}
                            />
                        </span>
                        <span className="text-sm md:block hidden font-medium text-white dark:text-slate-300">
                            {selected?.name ? selected?.name : language[0].name}
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute min-w-[100px] ltr:right-0 rtl:left-0 md:top-[50px] top-[38px] w-auto max-h-60 overflow-auto border border-slate-200 dark:border-slate-700 rounded bg-black dark:bg-slate-800 mt-1 ">
                            {language.map((item, i) =>
                            (
                                <Listbox.Option key={i} value={item} as={Fragment}>
                                    {({ active }) => (
                                        <li
                                            className={`
                        w-full border-b border-b-gray-500 border-opacity-10 px-2 py-2 last:border-none last:mb-0 cursor-pointer first:rounded-t last:rounded-b
                          ${active
                                                    ? "bg-[#6266647F] dark:bg-slate-700 dark:bg-opacity-70 bg-opacity-50 dark:text-white "
                                                    : "text-slate-600 dark:text-slate-300"
                                                }
                          `}
                                        >
                                            <div className="flex items-center space-x-2 rtl:space-x-reverse" onClick={() => handleLanguage(item, i)}>
                                                <span className="flex-none">
                                                    <span className="lg:w-6 lg:h-6 w-4 h-4 rounded-full inline-block">
                                                        <img
                                                            src={item.image}
                                                            alt=""
                                                            className="w-full h-full object-cover relative top-1 rounded-full"
                                                            // style={{ width: "2em", height: "2em", borderRadius: "2em" }}

                                                        />
                                                    </span>
                                                </span>
                                                <span className="flex-1 lg:text-base text-sm capitalize text-white">
                                                    {item.name}
                                                </span>
                                            </div>
                                        </li>
                                    )}
                                </Listbox.Option>
                            )

                            )}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
            {/* <button style={{ width: "2em" }}>
                <select style={{ width: "2em" }}>
                    {
                        language.map((item) => (
                            <option>{item.name}</option>
                        ))
                    }
                </select>
            </button> */}

        </div>
    )
}

export default Language
