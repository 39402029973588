import React, { useEffect, useState } from 'react'
import Home from './Home'
import MostLikedEvent from './MostLikedEvent'
import MostPopularEvent from './MostPopularEvent'
import MostTrendingEvent from './MostTrendingEvent'
import PopularCategory from './PopularCategory'
import CommingSoonEvents from './CommingSoonEvents'
import HowItWorks from './HowItWorks'
import WhyChooseUs from './WhyChooseUs'
import TopDrivers from './TopDrivers'

import Loader from '../preloander/Loander'
import { SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import 'swiper/css/bundle';
import { Link } from 'react-router-dom'
import image1 from "../../assets/img/logo/becomeOrg.jpg"
import image2 from "../../assets/img/logo/becomeOrg.jpg"
import image3 from "../../assets/img/logo/becomeOrg.jpg"
import image4 from "../../assets/img/logo/becomeOrg.jpg"
import MostTrendingEvent2 from './MostTrendingEvent2'
import MostPopularEvent2 from './MostPopularEvent2'
import AllEventSwiper from './AllEventSwiper'



const popular_categories = [
    //tab-1 data
    {
        id: 1,
        image: image1,
        title: "Singers"
    },
    {
        id: 2,
        image: image2,
        title: "Bands & Group"
    },
    {
        id: 3,
        image: image3,
        title: "Tributes"
    },
    {
        id: 4,
        image: image4,
        title: "Solo Musicians"
    },
    {
        id: 5,
        image: image1,
        title: "Singers"
    },
    {
        id: 6,
        image: image2,
        title: "Bands & Group"
    },
    {
        id: 7,
        image: image3,
        title: "Tributes"
    },
    {
        id: 8,
        image: image4,
        title: "Solo Musicians"
    },
    //tab-2 data
    {
        id: 9,
        image: image3,
        title: "Tributes"
    },
    {
        id: 10,
        image: image4,
        title: "Solo Musicians"
    }
]




const HomeMainArea = () => {

    const [loading, setLoading] = useState(true)

    useEffect(() => {

        setTimeout(() => {

            setLoading(false)

        }, 1000);

    }, [])

    return (

        <>

            {
                loading ? <Loader /> :

                    <div className='overflow-x-hidden'>

                        <AllEventSwiper />

                        <Home />
                        {/* <MostLikedEvent /> */}
                        {/* <MostTrendingEvent /> */}
                        <MostTrendingEvent2 />
                        {/* <MostPopularEvent /> */}
                        <MostPopularEvent2 />

                        <TopDrivers />
                        {/* <HowItWorks /> */}


                        {/* <WhyChooseUs /> */}
                    </div>
            }


        </>

    )
}

export default HomeMainArea
